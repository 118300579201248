<template>
    <div class="pApplication" v-if="$store.getters.getLoggedInUser.id">
        <div class="pApplication__header">
            <div class="row">
                <div class="columns text-left">
                    <router-link to="/tool">
                        <img :src="logo" class="pApplication__headerLogo"/>
                    </router-link>
                </div>
                <div class="columns text-right">
                    <ul class="pApplication__headerMenu">
                        <li class="show-for-medium" v-if="user.admin">
                            <a :href="exportUrl" class="--download">Export</a>
                        </li>
                        <li class="show-for-medium">
                            <router-link to="/tool" class="--map">Karte</router-link>
                        </li>
                        <li class="show-for-medium">
                            <a href="https://www.lebensmitteltechnik-deutschland.com/" target="_blank" class="--website">Website</a>
                        </li>
                        <li>
                            <router-link to="/tool/profil" class="--profile">Profil</router-link>
                        </li>
                        <li>
                            <router-link to="/logout" class="--logout">Logout</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="pApplication__main">
            <notifications
                    :duration="3000"
                    class="pApplication__notifications"
            />

            <router-view></router-view>
        </div>

        <div class="pApplication__loading" v-show="loadingIndex > 0">
            <loading/>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                menu: {}
            }
        },
        computed: {
            loadingIndex() {
                return this.$store.getters.getLoadingIndex;
            },
            user() {
                return this.$store.getters.getLoggedInUser;
            },
            logo() {
                return window.logoUrl;
            },
            exportUrl() {
                return window.exportUrl + '?exportKey=' + this.user.exportKey;
            }
        },
        mounted() {
            this.$store.dispatch("getEnvironment");
            this.$store.dispatch("getLoggedInUser");
        },
        watch: {
            user(newVal) {
                let _this = this;
                setTimeout(function() {
                    _this.menu = new Foundation.DropdownMenu($(_this.$refs.sidebarMenu), {closingTime: 500});
                }, 1);
            }
        }
    }
</script>
