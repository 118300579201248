import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

import Application from "./view/pages/Application";
import Security from "./view/pages/Security";
import Login from "./view/pages/security/Login";
import PasswordForget from "./view/pages/security/PasswordForget";
import PasswordReset from "./view/pages/security/PasswordReset";
import Logout from "./view/pages/security/Logout";
import Profile from "./view/pages/application/Profile";
import Register from "./view/pages/security/Register";
import Confirm from "./view/pages/security/Confirm";
import Map from "./view/pages/application/Map";
import Landingpage from "./view/pages/Landingpage";

const routes = [
    {
        path: '',
        name: 'Landingpage',
        component: Landingpage
    },
    {
        path: '/tool',
        name: 'Application',
        component: Application,
        children: [
            {
                path: '',
                component: Map
            },
            {
                path: 'profil',
                component: Profile
            }
        ]
    },
    {
        path: '/',
        name: 'Security',
        component: Security,
        children: [
            {
                name: 'register',
                path: '/registrierung',
                component: Register
            },
            {
                name: 'login',
                path: '/login',
                component: Login
            },
            {
                name: 'logout',
                path: '/logout',
                component: Logout
            },
            {
                name: 'passwordForget',
                path: '/passwort-vergessen',
                component: PasswordForget
            },
            {
                name: 'passwordReset',
                path: '/passwort-zuruecksetzen/:passwordResetToken',
                component: PasswordReset
            },
            {
                name: 'confirm',
                path: '/bestaetigung/:confirmationToken',
                component: Confirm
            }
        ]
    },
];


const router = new Router({
    mode: 'hash',
    routes: routes,
    linkExactActiveClass: 'active'
});

export default router;
