<template>
    <div class="pSecurity" :class="{'--login': loginProcess}">
        <div class="pSecurity__wrapper">
            <notifications
                    :duration="3000"
                    class="pSecurity__notifications"
            />

            <div class="pSecurity__loading" v-show="loading">
                <loading/>
            </div>

            <div class="pSecurity__content" v-show="!loading" :class="{'--large': largeContent}">
                <img :src="logo"/>
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from "../components/Loading";
    import auth from "../../plugins/auth";

    export default {
        components: {
            Loading
        },
        data() {
            return {
                loginProcess: false,
                loading: false,
            }
        },
        computed: {
            largeContent: function () {
                if (this.$route.name === 'register') {
                    return true;
                }

                return false;
            },
            logo() {
                return window.logoUrl;
            }
        },
        methods: {
            login() {
                this.loginProcess = true;
                setTimeout(() => {
                    this.$router.push("/tool");
                }, 500);
            },
            submitLogin(email, password) {
                this.showLoading();
                this.$http.post("token", {email: email, password: password}).then(response => {
                    auth.setAuth(response.data.token);
                    auth.setRefreshToken(response.data.refresh_token);
                    this.login();
                }, response => {
                    this.hideLoading();
                });
            },
            showLoading() {
                this.loading = true;
            },
            hideLoading() {
                this.loading = false;
            }
        }
    }
</script>
