<template>
    <form v-on:submit.prevent="register()">
        <div class="row">
            <div class="large-6 small-12 columns">
                <h3>Anwendungsdaten</h3>
                <input-text v-model="user.email" label="E-Mail" :errors="formErrors.email"/>
                <input-text v-model="user.plainPassword" type="password" label="Passwort"
                            :errors="formErrors.plainPassword"/>
                <input-text v-model="user.plainPasswordRepeat" type="password"
                            label="Passwort (Wiederholung)"
                            :errors="formErrors.plainPasswordRepeat"/>
            </div>

            <div class="large-6 small-12 columns">
                <h3>Persönliche Daten</h3>

                <div class="row">
                    <div class="small-12 medium-12 large-6 columns">
                        <input-text v-model="user.firstName" label="Vorname"
                                    :errors="formErrors.firstName"/>
                    </div>
                    <div class="small-12 medium-12 large-6 columns">
                        <input-text v-model="user.lastName" label="Nachname"
                                    :errors="formErrors.lastName"/>
                    </div>
                    <div class="small-12 medium-12 large-12 columns">
                        <input-text v-model="user.company" label="Unternehmen"
                                    :errors="formErrors.company"/>
                    </div>
                    <div class="small-12 medium-12 large-12 columns">
                        <input-text v-model="user.street" label="Straße" :errors="formErrors.street"/>
                    </div>
                    <div class="small-12 medium-6 large-4 columns">
                        <input-text v-model="user.zip" label="PLZ" :errors="formErrors.zip"/>
                    </div>
                    <div class="small-12 medium-6 large-8 columns">
                        <input-text v-model="user.city" label="Stadt" :errors="formErrors.city"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center">
            <a href="/" class="button secondary">Zurück zur Startseite</a>
            <button type="submit" class="button">Registrieren</button>
        </div>
    </form>
</template>

<script>
    import util from "../../../plugins/util";

    export default {
        data() {
            return {
                user: {
                    plainPassword: '',
                    plainPasswordRepeat: ''
                },
                formErrors: {}
            }
        },
        methods: {
            register() {
                this.formErrors = {};

                if (
                    this.user.plainPassword.length === 0
                    || this.user.plainPassword !== this.user.plainPasswordRepeat
                ) {
                    this.formErrors.plainPassword = ['Das Passwort darf nicht leer sein und muss gleich der Wiederholung sein.'];
                    return;
                }

                this.$parent.showLoading();
                this.$http.post("users", this.user).then(response => {
                    this.$notify({
                        type: 'success',
                        text: 'Registrierung erfolgreich. Bitte bestätigen Sie den Link, den wir Ihnen per Mail geschickt haben'
                    });
                    this.$router.push('/login');
                    this.$parent.hideLoading();
                }, response => {
                    this.formErrors = util.mapConstraints(response.body);
                    this.$parent.hideLoading();
                });
            }
        }
    }
</script>
